/* eslint import/prefer-default-export: off */
/* eslint class-methods-use-this: off */
/* eslint import/no-extraneous-dependencies: off */

import videojs from 'video.js';

const Component = videojs.getComponent('Component');

export class TitleBar extends Component {
    constructor(player, options) {
        super(player, options);
        if (options.text) this.updateTextContent(options.text);
    }

    createEl() {
        return videojs.dom.createEl('div', {
            className: 'vjs-title-bar',
        });
    }

    updateTextContent(text) {
        if (typeof text !== 'string') {
            text = 'Title unknown';
        }
        videojs.dom.emptyEl(this.el());
        videojs.dom.appendContent(this.el(), text);
    }
}
