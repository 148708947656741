import Head from 'next/head';
import Script from 'next/script';

const MetaHeader = (Comp) => (props) => {
    return (
        <>
            <Head>
                <title>
                    {props?.meta?.title
                        ? props?.meta?.title
                        : 'Luxury29 Car Rental'}
                </title>
                <meta name="theme-color" content="#987B51"></meta>
                <meta
                    name="description"
                    content={
                        props?.meta?.description
                            ? props?.meta?.description
                            : 'Luxury29 Car Rental'
                    }
                />
                <meta
                    name="keywords"
                    content={
                        props?.meta?.keywords
                            ? props?.meta?.keywords
                            : 'Luxury29 Car Rental'
                    }
                />
                <meta name="robots" content="max-image-preview:large" />
                <meta
                    property="og:title"
                    content={
                        props?.meta?.title
                            ? `${props?.meta?.title}`
                            : 'Luxury29 Car Rental'
                    }
                    key="ogtitle"
                />
                <meta
                    property="og:description"
                    content={
                        props?.meta?.description
                            ? `${props?.meta?.description}`
                            : 'Luxury29 Car Rental'
                    }
                    key="ogdesc"
                />
                <meta
                    property="og:url"
                    content={
                        props?.meta?.url ? props?.meta?.url : process.env.HOST
                    }
                    key="ogurl"
                />
                <meta
                    property="og:image"
                    content={
                        props?.meta?.image
                            ? `${process.env.HOST}/${props?.meta?.image}`
                            : `/main-pic.png`
                    }
                    key="ogimage"
                />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:type" content="website" />
                {/* Facebook Pixel Code */}
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');
                fbq('track', 'PageView');
              `,
                    }}
                />
                <noscript>
                    <img
                        height="1"
                        width="1"
                        style={{ display: 'none' }}
                        src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
                    />
                </noscript>
                {/* End Facebook Pixel Code */}
                {/* Google Tag Manager */}
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}');                
                `,
                    }}
                />
                <noscript>
                    <iframe
                        src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}`}
                        height={0}
                        width={0}
                        style={{ display: 'none', visibility: 'hidden' }}
                    ></iframe>
                </noscript>
                {/* End Google Tag Mananger */}
            </Head>
            <Comp {...props} />
        </>
    );
};

export default MetaHeader;
