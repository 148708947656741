/* eslint @next/next/no-img-element: off */
import { FaPlus } from 'react-icons/fa';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { titleExperience } from '../../MockData/MockData';
import styles from './Experience.module.scss';
import iconAirport from '../../../assets/images/home/experience/airport-experience-mobile.png';
import iconCity from '../../../assets/images/home/experience/city-experience-mobile.png';
import iconTravel from '../../../assets/images/home/experience/travel-experience-mobile.png';
import iconConcert from '../../../assets/images/home/experience/concert-experience-mobile.png';
import iconSchool from '../../../assets/images/home/experience/school-experience-mobile.png';
import iconMedical from '../../../assets/images/home/experience/medical-experience-mobile.png';
import thumborLoader from '../../../../utils/thumborLoader';

const Experience = ({ ref }) => {
    const router = useRouter();
    const { locale } = router;
    const imgMap = [
        {
            src: iconAirport.src,
        },
        {
            src: iconCity.src,
        },
        {
            src: iconTravel.src,
        },
        {
            src: iconConcert.src,
        },
        {
            src: iconSchool.src,
        },
        {
            src: iconMedical.src,
        },
    ];

    const title_expensive = {
        th: {
            title: 'ประสบการณ์',
            desc: 'บริการขับรถมืออาชีพ',
            more: 'ดูเพิ่มเติม',
        },
        en: {
            title: 'EXPERIENCE',
            desc: 'Professional Driving',
            more: 'SEE MORE',
        },
        cn: {
            title: '服务',
            desc: '专业代驾服务',
            more: '查看更多',
        },
    };

    return (
        <section className={styles.sectionExperience} id="sectionExperience">
            <div className={styles.sectionBg}>
                <div className={styles.lgContent}>
                    <div className={styles.text}>
                        <div className={styles.titleSectionLg}>
                            <h4>{title_expensive[locale].title}</h4>
                        </div>
                        <div className={styles.bottomTitleLg}>
                            <h5>{title_expensive[locale].desc}</h5>
                        </div>
                    </div>
                    <div className={styles.sectionContentEx}>
                        {titleExperience[locale].map((item, index) => (
                            <div className={styles.listItem} key={index}>
                                <div className={styles.itemImg}>
                                    <Image
                                        loader={thumborLoader}
                                        width="37"
                                        height="37"
                                        src={imgMap[index].src}
                                        alt="img-title"
                                    />
                                </div>
                                <div className={styles.listText}>
                                    <p className={styles.itemText}>
                                        {item.title}
                                    </p>
                                    <div className={styles.itemDesc}>
                                        <p>{item.desc}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className={styles.secSeeMore}>
                            <div className={styles.subSeeMore}>
                                <Link href="/" legacyBehavior>
                                    <a className={styles.viewMore}>
                                        {title_expensive[locale].more}
                                    </a>
                                </Link>
                                <FaPlus style={{ color: '#DAA520' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Experience;
