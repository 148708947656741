/* eslint @next/next/no-img-element: off */
import { useRouter } from 'next/router';
import Image from 'next/image';
import homeAboutImg from '../../../assets/images/home/home-about-mb-v2.png';
import homeAboutImgLg from '../../../assets/images/home/home-about-v2.jpeg';
import styles from './About.module.scss';
import { titleAboutHome } from '../../MockData/MockData';
import VideoJs from '../../VideoJs';
import useMedia from '../../../../hook/useMedia';
import thumborLoader from '../../../../utils/thumborLoader';

const About = (props) => {
    const router = useRouter();
    const { locale } = router;
    const isMd = useMedia('(min-width: 768px)');
    const video_src = '/videos/vip29-promo.mp4';
    return (
        <section className={styles.sectionAbout} id="aboutUs">
            <div className={styles.bgAboutContent}>
                <Image
                    loader={thumborLoader}
                    src={isMd ? homeAboutImgLg : homeAboutImg}
                    alt="home-about-background"
                    loading="lazy"
                    className={styles.imgBgAbout}
                    objectFit="cover"
                />
            </div>
            <div className={styles.sectionText}>
                <div className={styles.videoContent}>
                    <VideoJs src={video_src} />
                </div>
                <div className={styles.textContent}>
                    <span className={styles.textAbout}>ABOUT</span>
                    <div className={styles.textTop}>
                        <h2>{titleAboutHome[locale].topTitle}</h2>
                    </div>
                    <div className={styles.textNormal}>
                        <p>{titleAboutHome[locale].midTitle}</p>
                    </div>
                    <div className={styles.textNormal}>
                        <p>{titleAboutHome[locale].bottomTitle}</p>
                    </div>
                </div>
            </div>
            <div className={styles.sectionTextLg}>
                <div className={styles.contentText}>
                    <div className={styles.videoWrap}>
                        <VideoJs src={video_src} />
                    </div>
                    <div className={styles.textWrap}>
                        <span className={styles.textAbout}>ABOUT</span>
                        <div className={styles.textTop}>
                            <h2>{titleAboutHome[locale].topTitle}</h2>
                        </div>
                        <div className={styles.textNormal}>
                            <p>{titleAboutHome[locale].midTitle}</p>
                        </div>
                        <div className={styles.textNormal}>
                            <p>{titleAboutHome[locale].bottomTitle}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
