/* eslint import/no-extraneous-dependencies: off */

import { useRef, useEffect, useCallback } from 'react';
import videojs from 'video.js';
import styles from './VideoJs.module.scss';
import { TitleBar } from './vjs-ui';
import thumborLoader from '../../../utils/thumborLoader';

export const MimetypesKind = {
    opus: 'video/ogg',
    ogv: 'video/ogg',
    mp4: 'video/mp4',
    mov: 'video/mp4',
    m4v: 'video/mp4',
    mkv: 'video/x-matroska',
    m4a: 'audio/mp4',
    mp3: 'audio/mpeg',
    aac: 'audio/aac',
    caf: 'audio/x-caf',
    flac: 'audio/flac',
    oga: 'audio/ogg',
    wav: 'audio/wav',
    m3u8: 'application/x-mpegURL',
    mpd: 'application/dash+xml',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    gif: 'image/gif',
    png: 'image/png',
    svg: 'image/svg+xml',
    webp: 'image/webp',
};

const uaDetect = () => {
    const { userAgent } = navigator;
    const isLine = /\bLine\//i.test(userAgent) || false;
    const isMobile = /(iPad|iPhone|Android|Mobile)/i.test(userAgent) || false;
    const rules = [
        'WebView',
        '(iPhone|iPod|iPad)(?!.*Safari/)',
        'Android.*(wv|.0.0.0)',
    ];

    const regex = new RegExp(`(${rules.join('|')})`, 'ig');
    const isInApp = Boolean(userAgent.match(regex));

    return {
        isMobile,
        isLine,
        isInApp,
        userAgent,
    };
};

const VideoJsComponent = (props) => {
    const videoRef = useRef(null);

    const onScroll = useCallback(() => {
        const playVideoOnScroll = (offset) => {
            const { pageYOffset, scrollY } = window;
            const { isMobile } = uaDetect();
            // if (!isMobile) {
            //     if (pageYOffset >= offset && scrollY >= offset) {
            //         videojs(videoRef.current).ready(function () {
            //             this.play();
            //         });
            //     } else {
            //         videojs(videoRef.current).ready(function () {
            //             this.pause();
            //         });
            //     }
            // }
        };
        if (videoRef.current) {
            playVideoOnScroll(240);
        }
    }, []);

    useEffect(() => {
        // add eventlistener to window
        window.addEventListener('scroll', onScroll, { passive: true });
        // remove event on unmount to prevent a memory leak with the cleanup
        return () => {
            window.removeEventListener('scroll', onScroll, { passive: true });
        };
    }, []);

    const bindEvent = (player) => {
        player.on('play', (e) => {
            //
        });
        player.on('pause', (e) => {
            //
        });
        player.on('ended', (e) => {
            //
        });
    };

    const initPlayer = (player) => {
        videojs.registerComponent('TitleBar', TitleBar);
        player.addChild('TitleBar', { text: 'Luxury29 Car Rental Present' });
        videojs(videoRef.current).ready(function () {
            this.playsinline(true);
        });
    };

    useEffect(() => {
        if (videoRef.current) {
            const { src } = props;
            const player = videojs(videoRef.current, {
                controls: true,
                preload: 'auto',
                aspectRatio: '16:9',
                autoplay: false,
                muted: true,
                poster: thumborLoader({
                    src: `/poster_v2.jpeg`,
                    width: 640,
                    quality: 75,
                }),
                sources: [
                    {
                        src,
                        type: MimetypesKind.mp4,
                    },
                ],
            });

            initPlayer(player);
            bindEvent(player);
        }
    }, []);

    return (
        <div className={styles.videoWrapper}>
            <video
                controls
                ref={videoRef}
                className="video-js"
                preload="none"
            />
        </div>
    );
};

export default VideoJsComponent;
