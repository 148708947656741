import Link from 'next/link'
import { useRouter } from 'next/router'
import styles from './ButtonViewMore.module.scss'

const ButtonViewMore = ({ style = {}, pathname = '', title = '' }) => {
    const router = useRouter()
    const { locale } = router
    const text = {
        th: 'ดูเพิ่มเติม',
        en: 'VIEW MORE',
        cn: '查看更多'
    }
    return (
        <a className={styles.btnViewMore} style={style} href={pathname} title={title}>{text[locale]}</a>
    )
}

export default ButtonViewMore