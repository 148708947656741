/* eslint @next/next/no-img-element: off */
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FaArrowRight } from 'react-icons/fa';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import styles from './ServicesPrice.module.scss'
import bgServicesPriceMobile from '../../../assets/images/home/servies-price/bg-services-price-mobile.png';
import imageCarLg from '../../../assets/images/home/servies-price/img-car-lg-v2.png'
import circleBig from '../../../assets/images/home/servies-price/circle-big-lg.png'
import circleSmall from '../../../assets/images/home/servies-price/circle-small-lg.png'
import bentleyMb from '../../../assets/images/home/servies-price/benley-flying.png'
import toyotaVellfireMb from '../../../assets/images/home/servies-price/toyota-allpard.png'
import rollRoyceMb from '../../../assets/images/home/servies-price/Black-Rolls-Royce.png'
import { titleServicesPrice } from '../../MockData/MockData';
import vipCardMb from '../../../assets/images/ourServices/card-vip-29-mb.png'
import vipCardLg from '../../../assets/images/ourServices/card-vip-29-lg.png'
import ButtonViewMore from '../../ButtonViewMore';


const ServicesPrice = (props) => {
    const router = useRouter()
    const { locale } = router
    const [authCheck, setAuthCheck] = useState(true)
    const title = {
        th: {
            services_price: 'ราคาค่าบริการ',
            special: 'สิทธิพิเศษสำหรับคุณ',
            register: 'ลงทะเบียน'
        },
        en: {
            services_price: 'SERVICES PRICE',
            special: 'SpecialForYou',
            register: 'REGISTER'
        },
        cn: {
            services_price: '服务费',
            special: '为您特别准备',
            register: '注册'
        }
    }


    const utmCheck = (utm) => {
        if (utm === null || utm === undefined) {
            return ''
        } else {
            const query = new URLSearchParams(utm).toString()
            return `?${query}`
        }
    }

    useEffect(() => {
        if (Cookies.get('vip29_member_auth')) {
            setAuthCheck(false)
        } else {
            setAuthCheck(true)
        }
    }, [])

    return (
        <section className={styles.sectionServicesPrice} id={'servicesPrice'}>
            <div className={styles.titleSection}>
                <strong>{title[locale].services_price}</strong>
            </div>
            <div className={styles.titleDetailTop}>
                <h3 className={styles.textMb}>{titleServicesPrice[locale].titleTop}</h3>
            </div>
            <Image loading="lazy" src={bgServicesPriceMobile.src} className={styles.imgHalfCircle} alt="bg-service-mb" width="130" height="420" />
            <div className={styles.bgCircleLg}>
                <Image
                    src={circleSmall.src}
                    className={styles.imgCircleSmall}
                    alt="circle-small"
                    loading="lazy"
                    width="479"
                    height="479"
                />
                <Image
                    src={circleBig.src}
                    className={styles.imgCircleBig}
                    alt="circle-big"
                    loading="lazy"
                    width="500"
                    height="500"
                />
            </div>
            <div className={styles.mainContentCar}>
                <Image
                    src={rollRoyceMb.src}
                    className={styles.imgCar}
                    alt="vip29 roll royce"
                    width="300"
                    height="150"
                />

                <Image
                    src={bentleyMb.src}
                    className={styles.imgCar}
                    alt="vip29 bentley"
                    width="300"
                    height="150"
                    style={{ padding: '5px' }}
                />

                <Image
                    src={toyotaVellfireMb.src}
                    className={styles.imgCar}
                    alt="vip29 toyota vellfire"
                    width="300"
                    height="150"
                />
            </div>
            <div className={styles.wrapContent}>
                <div className={styles.mainContentCarLg}>
                    <Image
                        src={imageCarLg.src}
                        alt='car roll royce car bentley car toyota vellfire'
                        className={styles.carLg}
                        width="2500"
                        height="225"
                    />
                </div>
                <div className={styles.btnViewMore}>
                    <ButtonViewMore pathname={`${locale === 'en' ? '' : locale}/OurServices${utmCheck(props?.url)}`} title='view more' />
                </div>
                {authCheck && <>
                    <div className={styles.vip29Club}>
                        <div className={styles.cardContent}>
                            <div className={styles.imgCard}>
                                <Image width="57" height="41" loading='lazy' src={vipCardMb.src} alt='vip-card-mb' className={styles.vipCardMb} />
                            </div>
                            <div className={styles.titleText}>
                                <div className={styles.text}>
                                    <p>VIP29 Club</p>
                                </div>
                                <div className={styles.text}>
                                    <p>{`# ${title[locale].special}`}</p>
                                </div>
                                <Link href={`/${locale === 'en' ? '' : locale}/member/register${utmCheck(props?.url)}`} legacyBehavior>
                                    <a className={styles.btnLogin}>{title[locale].register}</a>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={styles.vip29ClubLg}>
                        <div className={styles.cardContent}>
                            <div className={styles.imgCard}>
                                <Image
                                    src={vipCardLg.src}
                                    alt='vip-card-lg'
                                    className={styles.vipCardLg}
                                    width="57"
                                    height="41"
                                    loading="lazy"
                                />
                            </div>
                            <div className={styles.titleText}>
                                <div className={styles.textLgTop}>
                                    <div className={styles.text}>
                                        <p>{`VIP29 Club # ${title[locale].special}`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.btnLg}>
                                <Link href={`/${locale === 'en' ? '' : locale}/member/register${utmCheck(props?.url)}`} legacyBehavior>
                                    <a className={styles.btnLogin}>{title[locale].register}</a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>}
                <div className={styles.secViewMore}>
                    <div className={styles.subViewMore}>
                        <Link href={`/${locale === 'en' ? '' : locale}${utmCheck(props?.url)}`} legacyBehavior>
                            <a className={styles.viewMore} title='view more services'>
                                VIEW MORE SERVICES
                            </a>
                        </Link>
                        <FaArrowRight style={{ color: '#DAA520' }} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesPrice;
