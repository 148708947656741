import Image from 'next/image';
import { useRouter } from 'next/router';
import { titleServicesPrice } from '../../../MockData/MockData';
import styles from './ServicesPriceV2.module.scss';
import imgCarRolls from '../../../../assets/images/home/car/rolls-roy-v2.png';
import imgCarbentley from '../../../../assets/images/home/car/bentley-flying-v2.png';
import imgCarbentleyBentayga from '../../../../assets/images/home/car/bentley-bentayga-v2.png';
import imgCarBenz from '../../../../assets/images/home/car/benz-s600-v2-min.png';
import imgCarBenzSe from '../../../../assets/images/home/car/benz-s500-v2-min.png';
import imgCarAudi from '../../../../assets/images/home/car/audi-min.png';
import imgCarAlphard from '../../../../assets/images/home/car/alphard-v2.png';
import imgCarBenzGlc from '../../../../assets/images/home/car/mercedes-benz-glc220d.png';
import imgCarBenzE200 from '../../../../assets/images/home/car/mercedes-benz-e-200-coupe.png';
import thumborLoader from '../../../../../utils/thumborLoader';
import IconAlphard from '../../../../assets/images/logos/bg-alphard.png';
import IconPromotionAlphard from '../../../../assets/images/home/car/promotion-alphard.png';
import IconPromotionAlphardTh from '../../../../assets/images/home/car/promotion-alphard-th.png';

const ServicesPriceV2 = (props) => {
    const router = useRouter();
    const { locale } = router;
    const imgCarList = [
        {
            id: 8,
            thumbnail: imgCarBenzE200,
            alt: 'mercedes benz c 200 coupe',
            name: 'Mercedes Benz E 200 Coupe',
            price: '฿ 20,000',
        },
        {
            id: 7,
            thumbnail: imgCarAlphard,
            alt: 'car toyota Alphard',
            name: 'TOYOTA ALPHARD',
            price: '฿ 8,500',
        },
        {
            id: 9,
            thumbnail: imgCarBenzGlc,
            alt: 'mercedes benz glc 220d',
            name: 'Mercedes Benz GLC 220d',
            price: '฿ 20,000',
        },
        {
            id: 1,
            thumbnail: imgCarRolls,
            alt: 'car rolls royce',
            name: 'Rolls Royce Phantom',
            price: '฿ 250,000',
        },
        {
            id: 2,
            thumbnail: imgCarbentley,
            alt: 'car bentley',
            name: 'Bentley Flying SPUR w 12',
            price: '฿ 100,000',
        },
        {
            id: 5,
            thumbnail: imgCarbentleyBentayga,
            alt: 'car benz S600',
            name: 'Bentley Bentayga',
            price: '฿ 50,000',
        },
        {
            id: 3,
            thumbnail: imgCarBenzSe,
            alt: 'car benz S500E',
            name: 'Mercedes Benz S600',
            price: '฿ 50,000',
        },
        {
            id: 4,
            thumbnail: imgCarBenz,
            alt: 'car bentayga',
            name: 'Mercedes Benz S500E',
            price: '฿ 40,000',
        },
        {
            id: 6,
            thumbnail: imgCarAudi,
            alt: 'car audi',
            name: 'Audi Q7 45TFSI',
            price: '฿ 25,000',
        },
    ];
    const alphardDetail = {
        id: 7,
        thumbnail: imgCarAlphard,
        alt: 'car toyota Alphard',
        name: 'TOYOTA ALPHARD',
        price: '฿ 8,500',
    };

    const utmCheck = (utm) => {
        if (utm === null || utm === undefined) {
            return '';
        } else {
            const query = new URLSearchParams(utm).toString();
            return `?${query}`;
        }
    };

    const routeToCarDetailPage = (id) => {
        router.push(`/${id}/car-detail${utmCheck(props?.utm)}`);
    };

    return (
        <div className={styles.contentServicesPrice} id={'servicesPrice'}>
            <div className={styles.headingContent}>
                <h3>{titleServicesPrice[locale].titleTop}</h3>
            </div>
            <div className={styles.cardCarContent}>
                <div className={styles.otherCarContent}>
                    {imgCarList.map((item, index) => (
                        <div
                            className={styles.card}
                            key={index}
                            onClick={() => routeToCarDetailPage(item.id)}
                        >
                            {item.id === 7 ? (
                                <div className={styles.alphardPromo}>
                                    <Image
                                        loader={thumborLoader}
                                        src={
                                            locale === 'th'
                                                ? IconPromotionAlphardTh.src
                                                : IconPromotionAlphard.src
                                        }
                                        width={400}
                                        height={150}
                                        className={styles.bgPromo}
                                        alt="promotion-alphard"
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className={styles.cardCar}>
                                <div className={styles.imgCarContent}>
                                    <Image
                                        loader={thumborLoader}
                                        src={item.thumbnail}
                                        alt={item.alt}
                                        loading="lazy"
                                        className={styles.imgCar}
                                        objectFit="contain"
                                        width={400}
                                        height={300}
                                    />
                                </div>
                                <div className={styles.detailCar}>
                                    <p className={styles.carName}>
                                        {item.name}
                                    </p>
                                    <p
                                        className={styles.carPrice}
                                        style={{
                                            textDecoration:
                                                item.id === 7
                                                    ? 'line-through'
                                                    : '',
                                            textDecorationColor:
                                                item.id === 7 ? 'red' : '',
                                            textDecorationThickness:
                                                item.id === 7 ? '1px' : '',
                                        }}
                                    >
                                        {item.price}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServicesPriceV2;
