/* eslint no-console: off */
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import { GoogleMap, MarkerF, CircleF } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useMemo, useState } from 'react';

import styles from './PlaceAutocompleteHome.module.scss';

const PlacesAutocompleteHome = (props) => {
    const ICON_COLOR = '#DAA520';
    const COUNTRY_CODE = 'th';
    const API_KEY = process.env.GCP_GOOGLE_MAP_API_KEY;
    const LAT_KEY = `${props.name}_lat`;
    const LNG_KEY = `${props.name}_lng`;

    const [lat, setLat] = useState(13.736717); // default Bangkok
    const [lng, setLng] = useState(100.523186); // default Bangkok
    const [openModalMap, setOpenModalMap] = useState(props.onClickShowModal);

    const mapCenter = useMemo(() => ({ lat: lat, lng: lng }), [lat, lng]);
    const mapOptions = useMemo(
        () => ({
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: true,
            disableDefaultUI: false,
            clickableIcons: true,
            scrollwheel: true,
            gestureHandling: 'greedy',
        }),
        []
    );

    Geocode.setApiKey(API_KEY);
    Geocode.setLanguage(COUNTRY_CODE);
    Geocode.setRegion(COUNTRY_CODE.toUpperCase());

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: { componentRestrictions: { country: COUNTRY_CODE } },
        debounce: 300,
    });

    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        // clearSuggestions();
    });

    const refModalMap = useOnclickOutside(() => {
        console.log('out side modal map');
    });

    const handleInput = (e) => {
        // Update the keyword of the input element
        const _v = e.target.value;
        setValue(_v);
        props.callbackValue({
            address: _v,
            [LAT_KEY]: lat,
            [LNG_KEY]: lng,
        });
    };

    const handleSelect =
        ({ description }) =>
            () => {
                // When user selects a place, we can replace the keyword without request data from API
                // by setting the second parameter to "false"
                setValue(description, false);
                clearSuggestions();

                // Get latitude and longitude via utility functions
                getGeocode({ address: description }).then((results) => {
                    const { lat, lng } = getLatLng(results[0]);
                    setLat(lat);
                    setLng(lng);
                    props.callbackValue({
                        address: description,
                        [LAT_KEY]: lat,
                        [LNG_KEY]: lng,
                    });
                });
                setOpenModalMap(true);
            };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li key={place_id} onClick={handleSelect(suggestion)}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

    const onMapClick = async (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setLat(lat);
        setLng(lng);
        Geocode.fromLatLng(`${lat}`, `${lng}`).then(
            (response) => {
                const adr =
                    response.results[0].address_components.length > 0
                        ? response.results[0].address_components
                            .map((d) => {
                                if (
                                    d.types.length > 0 &&
                                    !d.types.includes('plus_code')
                                ) {
                                    return d.long_name;
                                }
                            })
                            .join(' ')
                        : '-';
                setValue(adr, false);
                props.callbackValue({
                    address: adr,
                    [LAT_KEY]: lat,
                    [LNG_KEY]: lng,
                });
            },
            (error) => {
                console.error('error', error);
            }
        );
    };

    return (
        props.isLoaded && (
            openModalMap && (
                <div className={styles.modalMapWrapper} ref={ref}>
                    <div className={styles.modalMap}>
                        <div
                            className={styles.mapContainer}
                            ref={refModalMap}
                        >
                            <input
                                className={styles.inputModalMap}
                                value={value}
                                onChange={handleInput}
                                disabled={!ready}
                                placeholder={props.placeholder}
                            />
                            {/* We can use the "status" to decide whether we should display the dropdown or not */}
                            {status === 'OK' && (
                                <ul
                                    className={
                                        styles.suggestionWrapperModalMap
                                    }
                                >
                                    {renderSuggestions()}
                                </ul>
                            )}
                            <GoogleMap

                                options={mapOptions}
                                zoom={14}
                                center={mapCenter}
                                mapTypeId={google.maps.MapTypeId.ROADMAP}
                                mapContainerStyle={{ width: '100%' }}
                                onLoad={(map) => console.log('Map Loaded')}
                                onClick={onMapClick}
                                mapContainerClassName={styles.googleMapContent}
                            >
                                <MarkerF
                                    position={mapCenter}
                                    onLoad={() =>
                                        console.log('Marker Loaded')
                                    }
                                />
                                {[2, 4].map((radius, idx) => {
                                    return (
                                        <CircleF
                                            key={idx}
                                            center={mapCenter}
                                            radius={radius}
                                            onLoad={() =>
                                                console.log(
                                                    'Circle Load...'
                                                )
                                            }
                                            options={{
                                                fillColor:
                                                    radius > 2
                                                        ? 'red'
                                                        : 'green',
                                                strokeColor:
                                                    radius > 4
                                                        ? 'red'
                                                        : 'green',
                                                strokeOpacity: 0.8,
                                            }}
                                        />
                                    );
                                })}
                            </GoogleMap>
                            <button
                                className={styles.btnCloseMap}
                                onClick={() => {
                                    setOpenModalMap(false);
                                    clearSuggestions();
                                    props.callbackCancel(false)
                                }}
                                type="button"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )

        )
    );
};

export default PlacesAutocompleteHome;
