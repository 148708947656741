/* eslint @next/next/no-img-element: off */
import Modal from '@mui/material/Modal';
import { ImageList, ImageListItem, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import styles from './Gallery.module.scss';
import oneGallery from '../../../assets/images/home/gallery/one-v2.png';
import twoGallery from '../../../assets/images/home/gallery/women-v2.jpeg';
import threeGallery from '../../../assets/images/home/gallery/RR1.png';
import fourGallery from '../../../assets/images/home/gallery/light-v2.jpeg';
import fiveGallery from '../../../assets/images/home/gallery/drive-v2.jpeg';
import sixGallery from '../../../assets/images/home/gallery/bf-v2.jpeg';
import twoLg from '../../../assets/images/home/gallery/women-lg-v2.jpeg';
import useMedia from '../../../../hook/useMedia';
import thumborLoader from '../../../../utils/thumborLoader';

const Gallery = () => {
    const router = useRouter();
    const { locale } = router;
    const lg = useMedia('(min-width: 1024px)');
    const [open, setOpen] = useState(false);
    const [imageModal, setImageModal] = useState('');
    const [altImage, setAltImage] = useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setImageModal('');
        setAltImage('');
        setOpen(false);
    };
    const itemData = [
        {
            cols: 2,
            rows: 2,
            src: oneGallery.src,
            alt: 'car gallery',
        },
        {
            cols: 2,
            rows: 1,
            src: fiveGallery.src,
            alt: 'women gallery',
        },
        {
            cols: 2,
            rows: 2,
            src: twoGallery.src,
            alt: 'man drive gallery',
        },
        {
            cols: 2,
            rows: 1,
            src: sixGallery.src,
            alt: 'women drive gallery',
        },
        {
            cols: 4,
            rows: 1,
            src: threeGallery.src,
            alt: 'drive gallery',
        },
        {
            cols: 4,
            rows: 2,
            src: fourGallery.src,
            alt: 'car drive gallery',
        },
    ];

    const itemDataLg = [
        {
            cols: 2,
            rows: 2,
            src: oneGallery.src,
            alt: 'car gallery',
        },
        {
            cols: 2,
            rows: 1,
            src: twoLg.src,
            alt: 'women gallery',
        },
        {
            cols: 2,
            rows: 2,
            src: fiveGallery.src,
            alt: 'drive gallery',
        },
        {
            cols: 2,
            rows: 4,
            src: threeGallery.src,
            alt: 'man drive gallery',
        },
        {
            cols: 2,
            rows: 4,
            src: sixGallery.src,
            alt: 'women drive gallery',
        },
        {
            cols: 2,
            rows: 2,
            src: fourGallery.src,
            alt: 'car drive gallery',
        },
    ];

    const srcset = (image = '', size = '', rows = 1, cols = 1) => {
        // return {
        //     src: `${image}?w=${size * cols}&h=${size * rows
        //         }&fit=crop&auto=format`,
        //     srcSet: `${image}?w=${size * cols}&h=${size * rows
        //         }&fit=crop&auto=format&dpr=2 2x`,
        // };
        return `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format`;
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: lg ? 700 : 400,
        bgcolor: 'background.paper',
        border: '2px solid #daa520',
        boxShadow: 24,
        p: 4,
    };

    const handleOpenModal = (image, alt) => {
        setImageModal(image);
        setAltImage(alt);
        handleOpen();
    };

    const title_gallery = {
        th: 'แกลลอรี่',
        en: 'GALLERY',
        cn: '画廊',
    };

    return (
        <section className={styles.sectionGallery} id="gallery">
            <div className={styles.titleGallery}>
                <p>{title_gallery[locale]}</p>
            </div>
            <ImageList variant="quilted" cols={4} className={styles.imageList}>
                {itemData.map((item) => (
                    <ImageListItem
                        key={item.src}
                        cols={item.cols || 1}
                        rows={item.rows || 1}
                        onClick={() => handleOpenModal(item.src)}
                    >
                        <div className={styles.backDrop}></div>
                        <Image
                            loader={thumborLoader}
                            loading="lazy"
                            width={221 * item.cols}
                            height={221 * item.rows}
                            src={item.src}
                            alt={item.alt}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <ImageList
                variant="quilted"
                cols={6}
                className={styles.imageListLg}
            >
                {itemDataLg.map((itemLg) => (
                    <ImageListItem
                        key={itemLg.src}
                        cols={itemLg.cols || 1}
                        rows={itemLg.rows || 1}
                    >
                        <Image
                            loader={thumborLoader}
                            loading="lazy"
                            src={itemLg.src}
                            width={321 * itemLg.cols}
                            height={321 * itemLg.rows}
                            alt={itemLg.alt}
                            onClick={() =>
                                handleOpenModal(itemLg.src, itemLg.alt)
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <div
                    className={`${styles['image-modal-wrapper']} ${
                        lg ? styles['image-modal-wrapper-lg'] : ''
                    }`}
                >
                    <Image
                        loader={thumborLoader}
                        className={styles['image-modal']}
                        src={imageModal}
                        alt={altImage}
                        width={lg ? 700 : 400}
                        height={lg ? 700 : 400}
                    />
                </div>
            </Modal>
            <div className={styles.containerBottom}>
                <div className={styles.shadowContent}></div>
            </div>
        </section>
    );
};

export default Gallery;
