/* eslint  @next/next/no-img-element: off */
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import styles from './Review.module.scss'
import dc from '../../assets/images/home/review/dc-footer-mobile.png'

import { titleReview } from '../MockData/MockData';

const Review = () => {

    const [active, setActive] = useState(0)
    const [datas, setDatas] = useState([])
    const [start, setStart] = useState(0)
    const [end, setEnd] = useState(0)

    const sliderLeft = () => {
        const s = end - 3
        const ctx = document.getElementById(`slide-${s}`)
        ctx.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        const i = active - 1
        setActive(i)
        setStart(s)
        setEnd(s)
    }

    const sliderRight = () => {
        const e = end + 3
        const ctx = document.getElementById(`slide-${e}`)
        ctx.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        const i = active + 1
        setActive(i)
        setEnd(e)
    }

    const bulletList = [1, 2, 3]

    useEffect(() => {
        const right = document.getElementById("slideRight")
        const left = document.getElementById("slideLeft")
        if (active === 0) {
            left.style.display = 'none'
        } else {
            left.style.display = 'flex'
        }
        if (active === 2) {
            right.style.display = 'none'
        } else {
            right.style.display = 'flex'
        }
    }, [active])

    return (
        <div className={styles.sectionReview} id="review">
            <div className={styles.topicTitle}>
                <span>REVIEW</span>
            </div>
            <div className={styles.topTitle}>
                <span>What they say about us</span>
            </div>
            <div className={styles.reviewList}>
                <div className={styles.carrousel}>
                    {titleReview.en.map((item, index) => (
                        <div className={styles.card} key={index}>
                            <div className={styles.cardContent}>
                                <img src={dc.src} className={styles.imgDc} alt="img-dc" />
                                <div className={styles.cardDetail}>
                                    <span className={styles.textDetail}>{item.detail}</span>
                                    <div className={styles.cardProfile}>
                                        <div className={styles.avatar}>
                                            <img src={item.avatar} alt="img-avatar" />
                                        </div>
                                        <div className={styles.profileDetail}>
                                            <div className={styles.name}>
                                                <span>{item.name}</span>
                                            </div>
                                            <div className={styles.position}>
                                                <span>{item.position}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.bullet}>
                    {bulletList.map((itemBullet, indexBullet) => (
                        <div className={active === indexBullet ? styles.indexPagination : styles.activePagination} key={indexBullet}></div>
                    ))}
                </div>
            </div>
            <div className={styles.reviewListLg}>
                <div className={styles.boxArrow}>
                    <button type='button' onClick={sliderLeft} className={styles.arrowSlide} id="slideLeft">
                        <FaChevronLeft style={{ color: '#FFFFFF' }} />
                    </button>
                </div>
                <div className={styles.contentPagination}>
                    <div className={styles.slider} id="sildeReview">
                        {titleReview.en.map((item, index) => (
                            <div className={styles.card} key={index} id={`slide-${index}`}>
                                <div className={styles.cardContent}>
                                    <img src={dc.src} className={styles.imgDc} alt="img-dc" />
                                    <div className={styles.cardDetail}>
                                        <span className={styles.textDetail}>{item.detail}</span>
                                        <div className={styles.cardProfile}>
                                            <div className={styles.avatar}>
                                                <img src={item.avatar} alt="img-avatar-lg" />
                                            </div>
                                            <div className={styles.profileDetail}>
                                                <div className={styles.name}>
                                                    <span>{item.name}</span>
                                                </div>
                                                <div className={styles.position}>
                                                    <span>{item.position}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.bullet}>
                        {bulletList.map((itemBullet, indexBullet) => (
                            <div className={active === indexBullet ? styles.indexPagination : styles.activePagination} key={indexBullet}></div>
                        ))}
                    </div>
                </div>
                <div className={styles.boxArrow} >
                    <button type='button' onClick={sliderRight} className={styles.arrowSlide} id="slideRight">
                        <FaChevronRight style={{ color: '#FFFFFF' }} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Review;
