/* eslint @next/next/no-img-element: off */
import { FaChevronDown } from 'react-icons/fa';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import ReactDatePicker from 'react-datepicker';
import Image from 'next/image';
import topHomeImg from '../../../assets/images/home/top-home-mb-v4.jpg';
import topHomeImgLg from '../../../assets/images/home/top-home-lg-v4.jpg';
import iconCarMenu from '../../../assets/images/home/icon-car-top-home.png';
import iconPickLocation from '../../../assets/images/home/icon-pick-up-location.png';
import iconPickDate from '../../../assets/images/home/icon-pick-up-date.png';
import styles from './TopHome.module.scss';
import { cars } from '../../MockData/MockData';
import ButtonBookingCar from '../../ButtonBookingCar';
import PlacesAutocompleteHome from '../../PlaceAutocompleteHome';
import useMedia from '../../../../hook/useMedia';
import thumborLoader from '../../../../utils/thumborLoader';

const TopHome = (props) => {
    const router = useRouter();
    const { locale } = router;
    const isMd = useMedia('(min-width: 768px)');
    const minDate = dayjs()?.add(2, 'day').format('YYYY-MM-DD');
    const formatDate = dayjs(minDate).toDate();
    const [optionList, setOptionList] = useState(false);
    const [selectType, setSelectType] = useState('');
    const [carId, setCarId] = useState(null);
    const [startDate, setStartDate] = useState(formatDate);
    const [startTime, setStartTime] = useState(formatDate);
    const [pickUpAdress, setPickUpAdress] = useState({});
    const [dropOffAdress, setDropOffAdress] = useState({});
    const [lat, setLat] = useState(13.736717); // default Bangkok
    const [lng, setLng] = useState(100.523186); // default Bangkok
    const title_menu = {
        th: {
            car_model: {
                title: 'รุ่นรถ',
                desc: 'เลือกรุ่นรถ',
            },
            pick_up: {
                title: 'สถานที่รับ',
                desc: 'เลือกสถานที่รับ',
            },
            pick_date: {
                title: 'วันที่รับ',
                desc: 'เลือกวันที่',
            },
            drop_off: {
                title: 'สถานที่ส่ง',
                desc: 'เลือกสถานที่ส่ง',
            },
        },
        en: {
            car_model: {
                title: 'CAR MODEL',
                desc: 'select car',
            },
            pick_up: {
                title: 'PICK UP ',
                desc: 'select address',
            },
            pick_date: {
                title: 'PICK UP DATE',
                desc: 'select date',
            },
            drop_off: {
                title: 'DROP OFF',
                desc: 'select address',
            },
        },
        cn: {
            car_model: {
                title: '汽车模型',
                desc: '选车',
            },
            pick_up: {
                title: '取货地点',
                desc: '选择地址',
            },
            pick_date: {
                title: '取货日期',
                desc: '选择日期',
            },
            drop_off: {
                title: '送货地点',
                desc: '选择地址',
            },
        },
    };
    const [carModelSelect, setCarModelSelect] = useState({
        title: title_menu[locale].car_model.title,
        desc: title_menu[locale].car_model.desc,
    });
    const [pickupSelect, setPickupSelect] = useState({
        title: title_menu[locale].pick_up.title,
        desc: title_menu[locale].pick_up.desc,
    });
    const [pickDate, setPickDate] = useState({
        title: title_menu[locale].pick_date.title,
        desc: title_menu[locale].pick_date.desc,
    });
    const [dropOffSelect, setDropOffSelect] = useState({
        title: title_menu[locale].drop_off.title,
        desc: title_menu[locale].drop_off.desc,
    });

    dayjs.locale('th');

    const titleTopHome = {
        sm: {
            th: {
                fisrtText: 'บริการคนขับแบบมืออาชีพ',
                secondText: 'เหนือกว่าทุกการเดินทาง',
            },
            en: {
                fisrtText: 'PROFESSIONAL CAR DRIVER SERVICES',
                secondText: 'Journey beyond ordinary',
            },
            cn: {
                fisrtText: '专业代驾服务',
                secondText: '超越平凡的旅程',
            },
        },
        lg: [
            {
                title: carModelSelect.title,
                desc: carModelSelect.desc,
                icon: iconCarMenu.src,
                type: 'car_model',
            },
            {
                title: pickupSelect.title,
                desc: pickupSelect.desc,
                icon: iconPickLocation.src,
                type: 'pick_up_location',
            },
            {
                title: pickDate.title,
                desc: pickDate.desc,
                icon: iconPickDate.src,
                type: 'pick_up_date',
            },
            {
                title: dropOffSelect.title,
                desc: dropOffSelect.desc,
                icon: iconPickLocation.src,
                type: 'drop_off_location',
            },
        ],
    };

    const today = new Date();

    const selectOptions = (type) => {
        if (type !== selectType) {
            setOptionList(true);
            setSelectType(type);
        } else {
            setOptionList(false);
            setSelectType('');
        }
    };

    const carSelected = (carBrand, carModel, id) => {
        setCarModelSelect({ title: carBrand, desc: carModel });
        setOptionList(!optionList);
        setSelectType('');
        setCarId(id);
    };

    const callbackValuePlaceInput = (val) => {
        setPickUpAdress({ name: 'pick_up_address', val: val });
        setPickupSelect({ title: val.address });
    };

    const callbackValueDropInput = (val) => {
        setDropOffAdress({ name: 'drop_off_address', val: val });
        setDropOffSelect({ title: val.address });
    };

    const callbackCancel = (render) => {
        setOptionList(render);
    };

    const onChangeDateTimeSelect = (value) => {
        const formatDate = dayjs(value).format('YYYY-MM-DD');
        const formatTime = dayjs(value).format('HH:mm');
        setPickDate({ title: formatDate, desc: formatTime });
        setStartDate(value);
        setStartTime(value);
    };

    const checkTimeFormat = (val) => {
        return /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(val);
    };

    const bookingQueryFilter = () => {
        let queryObj = {};
        queryObj = {
            car_id: carId ? carId : null,
            pick_up_address_lat: pickUpAdress.val?.pick_up_address_lat
                ? pickUpAdress.val?.pick_up_address_lat
                : null,
            pick_up_address_lng: pickUpAdress.val?.pick_up_address_lng
                ? pickUpAdress.val?.pick_up_address_lng
                : null,
            pick_up_date: dayjs(pickDate.title).isValid()
                ? pickDate.title
                : null,
            pick_up_time: checkTimeFormat(pickDate.desc) ? pickDate.desc : null,
            drop_off_address_lng: dropOffAdress.val?.drop_off_address_lng
                ? dropOffAdress.val?.drop_off_address_lng
                : null,
            drop_off_address_lat: dropOffAdress.val?.drop_off_address_lat
                ? dropOffAdress.val?.drop_off_address_lat
                : null,
        };
        const keys = Object.keys(queryObj).filter((k) => queryObj[k]);

        let rv = {};
        rv = {};
        for (const key of keys) {
            if (queryObj[key]) rv[key] = queryObj[key];
        }

        return rv;
    };

    const renderFormSelect = (type) => {
        if (selectType === 'car_model') {
            return (
                <div className={styles.optionDetail}>
                    <div className={styles.wrapOption}>
                        {cars.map((itemBooking, indexBooking) => (
                            <div className={styles.listCar} key={indexBooking}>
                                <button
                                    className={styles.btn}
                                    onClick={() =>
                                        carSelected(
                                            itemBooking.car_brand.name,
                                            itemBooking.car_model.name,
                                            itemBooking.id
                                        )
                                    }
                                >
                                    {itemBooking.name}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            );
        } else if (selectType === 'pick_up_location') {
            if (!props.isLoaded) {
                return <p>Loading...</p>;
            } else {
                return (
                    <div className={styles.pickLocation}>
                        <div className={styles.wrapOption}>
                            {/* We can use the "status" to decide whether we should display the dropdown or not */}
                            <div className={styles.contactSectionMap}>
                                <PlacesAutocompleteHome
                                    name={'pick_up_address'}
                                    isLoaded={props.isLoaded}
                                    label={'Pick up Adress'}
                                    placeholder={'Pick up Adress'}
                                    callbackValue={callbackValuePlaceInput}
                                    onClickShowModal={true}
                                    callbackCancel={callbackCancel}
                                />
                            </div>
                        </div>
                    </div>
                );
            }
        } else if (selectType === 'pick_up_date') {
            return (
                <div className={styles.pickUpDate}>
                    <div className={styles.contentSelect}>
                        <div className={styles.date}>
                            <label htmlFor="pick_up_date">Pick up Date:</label>
                            <div className={styles.pickDate}>
                                <ReactDatePicker
                                    wrapperClassName={styles.datePicker}
                                    selectsStart
                                    selected={startDate}
                                    onChange={(date) =>
                                        onChangeDateTimeSelect(date)
                                    }
                                    dateFormat={'yyyy-MM-dd'}
                                    minDate={startDate}
                                />
                            </div>
                        </div>
                        <div className={styles.time}>
                            <label htmlFor="pick_up_time">Pick up Time:</label>
                            <div className={styles.pickTime}>
                                <ReactDatePicker
                                    wrapperClassName={styles.timePicker}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    selectsStart
                                    selected={startTime}
                                    onChange={(time) =>
                                        onChangeDateTimeSelect(time)
                                    }
                                    dateFormat={'h:mm'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            if (!props.isLoaded) {
                return <p>Loading...</p>;
            } else {
                return (
                    <div className={styles.dropLocation}>
                        <div className={styles.pickLocation}>
                            <div className={styles.wrapOption}>
                                {/* We can use the "status" to decide whether we should display the dropdown or not */}
                                <div className={styles.contactSectionMap}>
                                    <PlacesAutocompleteHome
                                        name={'drop_off_address'}
                                        isLoaded={props.isLoaded}
                                        label={'Drop off Adress'}
                                        placeholder={'Drop off Adress'}
                                        callbackValue={callbackValueDropInput}
                                        onClickShowModal={true}
                                        callbackCancel={callbackCancel}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    };

    return (
        <section className={styles.topHomeBg}>
            <div className={styles.TopHomeContent} id="topHome">
                <div className={styles.bgContent}>
                    {isMd && (
                        <Image
                            loader={thumborLoader}
                            src={topHomeImgLg}
                            alt="top-home-background"
                            loading="lazy"
                            className={styles.imgBgLg}
                            objectFit="cover"
                            placeholder="blur"
                        />
                    )}
                    {!isMd && (
                        <Image
                            loader={thumborLoader}
                            src={topHomeImg}
                            alt="top-home-background"
                            loading="lazy"
                            className={styles.imgBg}
                            objectFit="cover"
                            placeholder="blur"
                        />
                    )}
                </div>
                <div className={styles.TopHomeText}>
                    <div className={styles.sectionTextBottom}>
                        <h1 className={styles.textBottom}>
                            {titleTopHome.sm[
                                locale
                            ].secondText.toLocaleUpperCase()}
                        </h1>
                        <h2 className={styles.textTop}>
                            {titleTopHome.sm[locale].fisrtText}
                        </h2>
                    </div>
                    <div className={styles.btnBooking}>
                        <ButtonBookingCar utm={props?.utm} />
                    </div>
                    <div className={styles.btnBookingLg}>
                        <div className={styles.contentBottom}>
                            {titleTopHome.lg.map((lgItem, lgIndex) => (
                                <div
                                    className={styles.selectBookingMenu}
                                    key={lgIndex}
                                    onClick={() => selectOptions(lgItem.type)}
                                >
                                    <div className={styles.bookingMenu}>
                                        <div className={styles.bookingTitle}>
                                            <img
                                                src={lgItem.icon}
                                                alt="icon-top"
                                            />
                                            <p className={styles.title}>
                                                {lgItem.title}
                                            </p>
                                        </div>
                                        <div className={styles.bookingDesc}>
                                            <p className={styles.desc}>
                                                {lgItem.desc}
                                            </p>
                                            <FaChevronDown
                                                style={{
                                                    color: '#635855',
                                                    width: '0.6em',
                                                    height: '0.6em',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className={styles.bookingLg}>
                                <ButtonBookingCar
                                    style={{ marginTop: '0' }}
                                    select={bookingQueryFilter()}
                                    utm={props?.utm}
                                />
                            </div>
                        </div>
                        {optionList && renderFormSelect()}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopHome;
