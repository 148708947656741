import Link from 'next/link'
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styles from './ButtonBookingCar.module.scss'

const ButtonBookingCar = ({ style = {}, select = {}, utm = {} }) => {
    const router = useRouter();
    const { locale } = router
    const title = {
        th: 'จองรถ',
        en: 'Book Car',
        cn: '预订车'
    }
    const newQuery = { ...select, ...utm }
    return (
        <button className={styles.btnBooking} style={style}
            onClick={() => router.push({ pathname: '/member/booking', query: select ? newQuery : '' })}>
            {title[locale]}
        </button>
    )
}

export default ButtonBookingCar