/* eslint no-console: off */
import { useLoadScript } from '@react-google-maps/api';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import About from '../src/components/Home/About';
import Experience from '../src/components/Home/Experience';
import Gallery from '../src/components/Home/Gallery';
import ServicesPrice from '../src/components/Home/ServicesPrice';
import TopHome from '../src/components/Home/TopHome';
import MetaHeader from '../src/components/MetaHeader';
import Review from '../src/components/Review';
import MainImage from '../public/main-pic.jpeg';
import ClientLayout from '../src/layouts/client/ClientLayout';
import { textConfig } from '../constants/textLang';
import ServicesPriceV2 from '../src/components/Home/V2/ServicesPriceV2';

const Home = (props) => {
    const router = useRouter();
    const [url, setUrl] = useState(null);
    const libraries = useMemo(() => ['places'], []);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.GCP_GOOGLE_MAP_API_KEY,
        libraries: libraries,
    });

    useEffect(() => {
        if (
            router.query?.utm_source &&
            router.query?.utm_medium &&
            router.query?.utm_campaign &&
            router.query?.utm_platform
        ) {
            const utm_action = router.query?.utm_action
                ? router.query?.utm_action
                : { utm_action: '' };
            const { utm_source, utm_medium, utm_campaign, utm_platform } =
                router?.query;
            setUrl({
                utm_source,
                utm_medium,
                utm_campaign,
                utm_action,
                utm_platform,
            });
        }
    }, []);

    return (
        <ClientLayout style={{ backgroundColor: 'black' }}>
            {/* Section Top Home */}
            <TopHome isLoaded={isLoaded} utm={url} />

            {/* Section About Us */}
            <About />

            {/* Section Services Price */}
            <ServicesPriceV2 utm={url} />

            {/* Section Gallery */}
            <Gallery />

            {/* Section Experience */}
            <Experience />

            {/* Section Review */}
            {/* <Review /> */}
        </ClientLayout>
    );
};

export const getStaticProps = async (ctx) => {
    const { locale } = ctx;
    const meta = {
        title: textConfig[locale]?.title_home,
        keywords: textConfig[locale]?.text_keyword,
        description: 'Luxury29 Car Rental Home Page',
        image: 'main-pic.jpeg',
    };

    return {
        props: { meta },
    };
};

export default MetaHeader(Home);
